<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" />
    <main>
      <section id="pointApply">
        <div class="w1300">

        <div class="top">

          <div class="main_title_box">
            <h3 class="main_title txt-bold">積分申請查詢</h3>
            <div class="img_box">
              <img src="img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="top_description">
            <p class="small txt-red">※請於活動結束後一個月內，至成果報告上傳簽到表格掃描檔及積分表格檔，逾期恕不計分。</p>
            <div class="filter">
              <form @submit.prevent>
                <div class="time_filter">
                  <!-- 透過關鍵字篩選 -->
                  <div class="input-wrap">
                    <p class="small txt-bold txt-dark_gray">搜尋活動主題</p>
                    <input
                      class="search"
                      type="text"
                      name="key"
                      placeholder="活動主題"
                      v-model.trim="params.keyword"
                    >
                    <button
                      type="submit"
                      @click="filterData()"
                    >查詢</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>

        <div class="bottom">

          <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="min180"><p>{{ th1 }}</p></th>
                  <th class="td-main txt-left"><p>{{ th2 }}</p></th>
                  <th class="td-main txt-left"><p>{{ th3 }}</p></th>
                  <th class="min100"><p>{{ th4 }}</p></th>
                  <th class="min240"><p>{{ th5 }}</p></th>
                </tr>
              </thead>
              <tbody id="table_data">
                <tr v-for="event in listArray" :key="event.uuid">
                  <td :data-title="th1" class="nowrap">
                    <p>{{ eventDate(event.start_date) }}<br>{{ eventTime(event.start_date) }}</p>
                  </td>
                  <td :data-title="th2" class="td-main txt-left"><p>{{ event.name }}</p></td>
                  <td :data-title="th3" class="td-main txt-left"><p>{{ event.apply_host }}</p></td>
                  <td
                    :data-title="th4"
                    class="nowrap"
                    :class="{
                      'txt-light_green': event.apply === 0 || event.apply === 2,
                      'txt-red': event.apply === -1 || event.apply === 1,
                    }"
                  >
                    <p>{{ eventStatus(event.apply) }}</p>
                  </td>
                  <td :data-title="th5" class="td-download td-download-multiple txt-left">
                    <div class="download_box">
                      <!-- 未繳費狀態：顯示 積分回覆函 / 前往繳費 -->
                      <button
                        v-if="event.apply === 1 || event.apply === 2"
                        class="small"
                        @click="openReplyModal(event.uuid)"
                      >積分回覆函</button>
                      <button
                        v-if="event.apply === 1"
                        class="payment small"
                        @click="goPayment(event)"
                      >前往繳費</button>

                      <!-- 已繳費狀態：可下載積分表格範例 -->
                      <a
                        v-if="event.apply === 2"
                        href="/download/積分表格檔範本.xls"
                        class="small"
                        download
                      >積分表格檔</a>

                      <!-- 已繳費且活動日期已過：可下載收據及上傳報告 -->
                      <button
                        v-if="event.apply === 2 && isEventOver(event.end_date)"
                        class="receipt small"
                        @click="openReceiptModal(event)"
                      >下載收據</button>
                      <router-link
                        v-if="event.apply === 2 && isEventOver(event.end_date)"
                        :to="`/register/point/search/upload?uuid=${event.uuid}`"
                        class="upload small"
                      >上傳成果報告</router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Pagination
            :pageButton="pageButton"
            :page="params.page"
            :totalPage="totalPage"
            @updatePage="updatePage"
          />

        </div>

        </div>
      </section>
    </main>

    <!-- lightbox -->
    <div
      id="modal_container-password"
      class="modal_container"
      :class="{ active: modalReply }"
      @click="closeReplyModal"
    >
      <div class="modal" @click.stop>
        <div class="close_btn h2" @click="closeReplyModal"><span>×</span></div>
        <form @submit.prevent>
          <ModalPassword
            v-model.trim="downloadReplyData.password"
            :item="inputFormat.passwordInput"
          />
          <button
            class="btn"
            type="button"
            @click="downloadReply"
          >
            送出
          </button>
        </form>
      </div>
    </div>

    <div
      id="modal_container-password"
      class="modal_container"
      :class="{ active: modalReceipt }"
      @click="closeReceiptModal"
    >
      <div class="modal" @click.stop>
        <div class="close_btn h2" @click="closeReceiptModal"><span>×</span></div>
        <form @submit.prevent>
          <ModalPassword
            v-model.trim="downloadReceiptData.password"
            :item="inputFormat.passwordInput"
          />
          <button
            class="btn"
            type="button"
            @click="downloadReceipt"
          >
            送出
          </button>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import ModalPassword from '@/components/form/ModalPassword.vue';
import Pagination from '@/components/Pagination.vue';
import { RegisterPointSubHeader } from '@/lib/const';
// eslint-disable-next-line no-unused-vars
import { eventsApplyIndex, eventsReply, eventsReceipt } from '@/lib/http';
import { mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'RegisterPointSearch',
  data() {
    return {
      /** Static Data */
      ...RegisterPointSubHeader,
      th1: '活動日期',
      th2: '活動主題',
      th3: '申請單位',
      th4: '進度',
      th5: '下載/操作',
      pageButton: 5,
      modalReply: false,
      modalReceipt: false,

      downloadReplyData: {
        uuid: '',
        password: '',
      },

      downloadReceiptData: {
        uuid: '',
        password: '',
      },

      inputFormat: {
        passwordInput: {
          label: '自設密碼',
          reminder: '',
          placeholder: '請輸入您的自設密碼',
        },
      },

      /** Search Params */
      params: {
        keyword: '',
        limit: 10,
        page: 1,
      },

      /** Axios Data */
      totalPage: 1,
      listArray: [],
    };
  },
  created() {
    if (this.$route.query.keyword) {
      this.params.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.num) {
      this.params.page = parseInt(this.$route.query.num, 10);
    }
    this.filterData(this.params.page);
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    eventDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    eventTime(value) {
      return moment(value).format('hh:mm');
    },
    eventStatus(status) {
      switch (status) {
        case -1:
          return '未通過';
        case 0:
          return '審核中';
        case 1:
          return '未繳費';
        case 2:
          return '已通過';
        default:
          return '';
      }
    },
    isEventOver(date) {
      if (moment().isAfter(date)) {
        return true;
      }
      return false;
    },
    filterData(pageValue = 1) {
      eventsApplyIndex({
        ...this.params,
        page: pageValue,
      }).then((result) => {
        this.totalPage = _.get(result, 'data.result.pageCount', 1);
        this.listArray = _.get(result, 'data.result.data', []);
        this.params.page = pageValue;
        this.$router.push({
          query: {
            keyword: this.params.keyword,
            num: pageValue,
          },
        });
      });
    },
    updatePage(value) {
      this.filterData(value);
    },
    openReplyModal(target) {
      this.modalReply = true;
      this.downloadReplyData.uuid = target;
    },
    openReceiptModal(item) {
      this.modalReceipt = true;
      this.downloadReceiptData.uuid = _.get(item, 'order.order_details[0].uuid', '');
    },
    closeReplyModal() {
      this.modalReply = false;
    },
    closeReceiptModal() {
      this.modalReceipt = false;
    },
    downloadReply() {
      if (this.downloadReplyData.password === '') {
        this.openModal({
          message: '自設密碼不得為空',
        });
      } else {
        eventsReply(this.downloadReplyData, 'docx').then((resError) => {
          if (resError) {
            this.openModal({
              message: resError,
            });
          }
        });
      }
    },
    downloadReceipt() {
      if (this.downloadReceiptData.uuid) {
        eventsReceipt(this.downloadReceiptData).then((result) => {
          if (result.data.status) {
            this.$cookies.set('receiptInfo', JSON.stringify(result.data.result.data));
            const routeData = this.$router.resolve({ path: '/receipt' });
            window.open(routeData.href, '_blank');
          } else {
            this.openModal({
              message: result.data.message,
            });
          }
        });
      }
    },
    goPayment(event) {
      this.$router.push({ path: `/register/point/search/payment?uuid=${event.uuid}` });
    },
  },
  watch: {
    /**
     * 偵測user使用網頁的上一頁功能時，重設頁數及重call API
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.params.page = parseInt(this.$route.query.num, 10);
      this.filterData(this.params.page);
    },
  },
  components: {
    PageSubHeader,
    ModalPassword,
    Pagination,
  },
};
</script>
